var tsml = {};
var resource = {
    pt: {
        "administration": "Administração",
        "main": "Principal",
        "attention": "Atenção",
        "home": "Início",
        "menu": "Menu",
        "open_menu": "Abrir menu",
        "close_menu": "Fechar menu",
        "expand_template": "Contrair",
        "add": "Adicionar",
        "cancel": "Cancelar",
        "confirm" : "Confirmar",
        "confirm_default" : "Tem certeza que deseja prosseguir? Essa operação é irreversível.",
        "back": "Voltar",
        "back_help": "Clique para voltar",
        "new_item": "Novo registro",
        "_new": "Novo",
        "new_help": "Clique para adicionar um novo item",
        "edit": "Editar",
        "edit_help": "Selecione um item e clique para editá-lo",
        "edit_need_check": "Para <b>editar</b> um item, é preciso selecionar uma linha da tabela antes.",
        "delete": "Excluir",
        "delete_help": "Selecione um item e clique para excluí-lo",
        "delete_help_2": "Excluir esse registro",
        "delete_need_check": "Para <b>excluir</b> um item, é preciso selecionar uma linha da tabela antes.",
        "delete_confirm": "Confirmo a exclusão",
        "delete_confirm_text": "Você <b>tem certeza</b> de que deseja excluir o(s) item(ns) selecionado(s)? Essa operação é irreversível. ",
        "search_continuous": "pesquisar...",
        "search": "Pesquisar",
        "search_help": "Clique para pesquisar itens da tabela abaixo",
        "clear": "Limpar",
        "clear_help": "Clique para limpar o formulário de pesquisa",
        "all": "tudo",
        "change_language": "Alterar idioma",
        "notifications": "Notificações",
        "logout": "Sair",
        "marketplace": "Marketplace",
        "configs": "Configurações",
        "personal_configs": "Configurações pessoais",
        "change_user": "Alterar usuário",
        "help": "Central de ajuda",
        "foruns": "Fóruns",
        "check_all": "Clique para selecionar todos os itens dessa página",
        "order_column": "Ordernar por essa coluna",
        "page_first": "Primeira página",
        "page_previous": "Página anterior",
        "page_next": "Próxima página",
        "page_last": "Última página",
        "first": "Primeira",
        "previous": "Anterior",
        "next": "Próxima",
        "last": "Última",
        "itens": "Registros",
        "page": "Página",
        "password": "Senha",
        "do_you_need_help": "Você precisa de ajuda?",
        "ok_understand": "OK, entendi",
        "welcome_to": "Bem vindo ao",
        "remember_data": "Lembrar meus dados",
        "forgot_password": "Esqueci minha senha",
        "enter": "Entrar",
        "dont_have_account": "Não tem conta?",
        "create_one": "Criar uma",
        "alert_client_error": "Ocorreu um erro na interação com o seu navegador. ",
        "alert_minimal_support": "Você está usando o Internet Explorer 10+ ou outro navegador antigo, desatualizado e com problemas de segurança. Para ter uma experiência de uso completa e segura  utilize  Google Chrome,  Mozilla Firefox ou Safari.",
        "alert_not_supported": "Você está usando o Internet Explorer 9 ou inferior, um navegador antigo, desatualizado e com problemas de segurança. Para ter uma experiência de uso completa e segura  utilize  Google Chrome,  Mozilla Firefox ou Safari.",
        "alert_not_found": "Ops...não entramos nenhum registro aqui.",
        "alert_offline": "Offline",
        "loading": "Carregando",
        "see_all": "Ver todas",
        "mark_as_read": "Marcar todas como lidas",
        "new_notification": "Nova notificação",
        "notification_received": "Recebidas",
        "notification_sent": "Enviadas",
        "notification_empty": "Por enquanto, nenhuma notificação para você.",
        "preferences": "Preferências",
        "repos": "Bibliotecas",
        "advanced": "Avançado",
        "terms": "Termos de uso",
        "terms_privacy": "Privacidade e segurança",
        "terms_technical": "Nível de serviço",
        "api": "Documentação de APIs",
        "changelog": "Novidades da versão",
        "ead": "Universidade EAD",
        "content_library": "Material educativo",
        "zapier": "Integração via Zapier",
        "tour": "Tour interativo",
        "select": "Selecione",
        "completed": "Concluído",
        "no_completed": "Não-Concluído",
        "aprroved": "Aprovado",
        "rejected": "Rejeitado",
        "selectdate":"Selecione uma data",
        "date_picker_locale" :"pt",
        "date_picker_format" :"d/m/Y", 
        "nothing_likely" :"1 - Nada provável",
        "extremely_likely" :"10 - Muito provável",
        "see_more":"Veja mais...",
        "no_item_found":"Nenhum registro encontrado",
        "privacy_message": "Utilizamos cookies e tecnologias semelhantes para melhorar sua experiência em nossos serviços. Ao continuar navegando, você concorda com estas condições.",
        "privacy_button": "Prosseguir",
        "system_notfound_1": "Parece que o endereço que você acessou está errado.",
        "system_notfound_2": "Nós não conseguimos encontrar seu sistema nesse endereço.",
        "system_notfound_3": "Por favor, verifica se o endereço que você digitou está correto.",
        "system_notfound_4": "Se continuar com problemas, contate o administrador da plataforma em sua empresa.",
        "constructor_workflow": "Workflow",
        "constructor_form": "Formulário",
        "constructor_fiels_activity": "Campos x Atividades",
        "constructor_salve": "Salvar", 
        "constructor_able": "Habilitado", 
        "constructor_visible": "Visível", 
        "constructor_manipulable": "Manipulável", 
        "constructor_not_manipulable": "Não manipulável",
        "constructor_create_form_message": "Esse processo não possui formulário associado. Deseja criar um?",
        "constructor_create_new_form": "Criar novo",
        "constructor_existing_form": "Formulário existente", 
        "constructor_add_fields": "Adicionar campos", 
        "constructor_field_text": "Texto", 
        "constructor_field_text_area": "Texto área",
        "constructor_field_date": "Data",
        "constructor_field_email": "Email",
        "constructor_field_multiple_selection": "Múltipla Seleção",
        "constructor_field_hour": "Hora",
        "constructor_field_numbers": "Números",
        "constructor_field_telephone": "Telefone",
        "constructor_field_archive": "Arquivo",
        "constructor_field_zip_code": "CEP",
        "constructor_field_currency": "Moeda",
        "constructor_field_nps": "NPS",
        "constructor_field_multiple_options": "Múltipla opções",
        "constructor_field_multiple_search": "Pesquisarr",
        "constructor_field_board": "Placa",
        "constructor_field_cell_phone": "Celular",
        "constructor_field_cpf": "CPF",
        "constructor_field_password": "Senha",
        "constructor_field_cnpj": "CNPJ",
        "constructor_field_rg": "RG",
        "constructor_field_hidden": "Escondido",
        "constructor_field_suggestion": "Sugestão",
        "constructor_new_section": "Nova seção +",
        "constructor_field_ownership": "Propriedade do campo",
        "constructor_advanced_options": "Opções avançadas",
        "select_an_option": "Selecione uma opção",
        
    },
    en: {
        "administration": "Administration",
        "main": "Main",
        "attention": "Attention",
        "home": "Home",
        "menu": "Menu",
        "open_menu": "Open menu",
        "close_menu": "Close menu",
        "expand_template": "Contract",
        "add": "Add",
        "cancel": "Cancel",
        "confirm" : "Confirm",
        "confirm_default" : "Are you sure you want to proceed? This operation is irreversible.",
        "back": "Back",
        "back_help": "Click to go back",
        "new_item": "New record",
        "_new": "New",
        "new_help": "Click to add a new item",
        "edit": "Edit",
        "edit_help": "Select an item and click to edit it",
        "edit_need_check": "To <b> edit </ b> an item, you must select a table row before.",
        "delete": "Delete",
        "delete_help": "Select an item and click to delete it",
        "delete_help_2": "Delete this record",
        "delete_need_check": "For <b> delete </ b> an item, you must select a table row before.",
        "delete_confirm": "I confirm the deletion",
        "delete_confirm_text": "Are you <b> sure </ b> that you want to delete the selected item (s)? This operation is irreversible.",
        "search_continuous": "search ...",
        "search": "Search",
        "search_help": "Click to search for items from the table below",
        "clear": "Clear",
        "clear_help": "Click to clear the search form",
        "all": "all",
        "change_language": "Change language",
        "notifications": "Notifications",
        "logout": "Log out",
        "marketplace": "Marketplace",
        "configs": "Settings",
        "personal_configs": "Personal settings",
        "change_user": "Change user",
        "help": "Help center",
        "Forums": "Forums",
        "check_all": "Click to select all items on this page",
        "order_column": "Order by this column",
        "page_first": "First page",
        "page_previous": "Previous page",
        "page_next": "Next page",
        "page_last": "Last page",
        "first": "First",
        "previous": "Previous",
        "next": "Next",
        "last": "last",
        "items": "Records",
        "page": "Page",
        "password": "Password",
        "do_you_need_help": "Do you need help?",
        "ok_understand": "Okay, I got it",
        "welcome_to": "Welcome to",
        "remember_data": "Remember my data",
        "forgot_password": "I forgot my password",
        "enter": "Enter",
        "dont_have_account": "No account?",
        "create_one": "Create one",
        "alert_client_error": "There was an error interacting with your browser.",
        "alert_minimal_support": "You are using Internet Explorer 10+ or ​​another old, out-of-date browser with security issues.For a full and secure use experience use  Google Chrome, Mozilla Firefox or Safari.",
        "alert_not_supported": "You are using Internet Explorer 9 or lower, an old, out-of-date browser with security issues.For a full and secure use experience use  Google Chrome, Mozilla Firefox or Safari.",
        "alert_not_found": "Oops ... we did not enter any record here.",
        "alert_offline": "Offline",
        "loading": "Loading",
        "see_all": "View all",
        "mark_as_read": "Mark as read",
        "new_notification": "New notification",
        "notification_received": "Received",
        "notification_sent": "Sent",
        "notification_empty": "For now, no new notifications for you.",
        "preferences": "Preferences",
        "repos": "Libraries",
        "advanced": "Advanced",
        "terms": "Terms of use",
        "terms_privacy": "Privacy and security",
        "terms_technical": "Service agreement",
        "api": "API Documentation",
        "changelog": "What's new",
        "ead": "University",
        "content_library": "Education library",
        "zapier": "Integration via Zapier",
        "tour": "Interactive tour",
        "select": "Select",
        "completed": "Completed",
        "no_completed": "Not Completed",
        "aprroved": "Aprroved",
        "rejected": "Rejected",
        "selectdate":"Select date",
        "date_picker_locale" :"en",
        "date_picker_format" :"m/d/Y",
        "nothing_likely" :"1 - Nothing likely",
        "extremely_likely" :"10 - Much likely",
        "see_more":"See more...",
        "no_item_found":"No item found",
        "privacy_message": "We use cookies and similar technologies to improve your experience on our services. By continuing to browse, you agree to these conditions.",
        "privacy_button": "Proceed",
        "system_notfound_1": "It looks like the address you entered is wrong.",
        "system_notfound_2": "We were unable to find your system at this address.",
        "system_notfound_3": "Please check that the address you entered is correct.",
        "system_notfound_4": "If you continue to have problems, contact the platform administrator at your company.",
        "constructor_workflow": "Workflow",    
        "constructor_form": "Form", 
        "constructor_fiels_activity": "Fields x Activity",
        "constructor_salve": "Save",      
        "constructor_able": "Able", 
        "constructor_visible": "Visible",  
        "constructor_manipulable": "Manipulable", 
        "constructor_not_manipulable": "Not manipulable", 
        "constructor_create_form_message": "This process has no associated form. Do you want to create one?", 
        "constructor_create_new_form": "Create new", 
        "constructor_existing_form": "Existing form", 
        "constructor_field_text": "Text", 
        "constructor_field_text_area": "Text area",
        "constructor_field_date": "Date",
        "constructor_field_email": "Email",
        "constructor_field_multiple_selection": "Multiple Selection",
        "constructor_field_hour": "Hour",
        "constructor_field_numbers": "Numbers",
        "constructor_field_telephone": "Telephone",
        "constructor_field_archive": "Archive",
        "constructor_field_zip_code": "Zip code",
        "constructor_field_currency": "Currency",
        "constructor_field_nps": "NPS",
        "constructor_field_multiple_options": "Multiple options",
        "constructor_field_multiple_search": "Search",
        "constructor_field_board": "Board",
        "constructor_field_cell_phone": "Cell phone",
        "constructor_field_cpf": "CPF",
        "constructor_field_password": "Password",
        "constructor_field_cnpj": "CNPJ",
        "constructor_field_rg": "RG",
        "constructor_field_hidden": "Hidden",
        "constructor_field_suggestion": "Suggestion",
        "constructor_new_section": "New section +",
        "constructor_field_ownership": "Field ownership",
        "constructor_advanced_options": "Advanced options",
        "select_an_option": "Select an option",
        
    },
    es: {
        "administration": "Administración",
        "main": "Principal",
        "attention": "Atención",
        "home": "Inicio",
        "menu": "Menú",
        "open_menu": "Abrir menú",
        "close_menu": "Cerrar menú",
        "expand_template": "Contrato",
        "add": "Agregar",
        "cancel": "Cancelar",
        "confirm" : "Confirmo",
        "confirm_default" : "¿Estás seguro de que quieres continuar? Esta operación es irreversible.",        
        "back": "Volver",
        "back_help": "Haga clic para volver",
        "new_item": "Nuevo registro",
        "_new": "Nuevo",
        "new_help": "Haga clic para agregar un nuevo elemento",
        "edit": "Editar",
        "edit_help": "Seleccione un elemento y haga clic para editarlo",
        "edit_need_check": "Para editar un elemento, debe seleccionar una fila de la tabla antes.",
        "delete": "Borrar",
        "delete_help": "Seleccione un elemento y haga clic para eliminarlo",
        "delete_help_2": "Eliminar este registro",
        "delete_need_check": "Para eliminar un elemento, debe seleccionar una fila de la tabla antes.",
        "delete_confirm": "Confirmo la exclusión",
        "delete_confirm_text": "Usted <b> está seguro de que desea eliminar el (los) elemento (s) seleccionado (s)? Esta operación es irreversible.",
        "search_continuous": "buscar ...",
        "search": "Buscar",
        "search_help": "Haga clic para buscar elementos de la tabla siguiente",
        "clear": "Limpiar",
        "clear_help": "Haga clic para borrar el formulario de búsqueda",
        "all": "todo",
        "change_language": "Cambiar idioma",
        "notifications": "Notificaciones",
        "logout": "Salir",
        "mercado": "mercado",
        "configs": "Configuración",
        "personal_configs": "Configuración personal",
        "change_user": "Cambiar usuario",
        "help": "Central de ayuda",
        "foruns": "Foros",
        "check_all": "Haga clic para seleccionar todos los elementos de esa página",
        "order_column": "Ordenar por esa columna",
        "page_first": "Primera página",
        "page_previous": "Página anterior",
        "page_next": "Página siguiente",
        "page_last": "Última página",
        "first": "Primera",
        "previous": "Anterior",
        "next": "Siguiente",
        "last": "Última",
        "itens": "Registros",
        "page": "Página",
        "password": "Contraseña",
        "do_you_need_help": "¿Necesita ayuda?",
        "ok_understand": "OK, entendí",
        "welcome_to": "Bienvenido al",
        "record_data": "Recordar mis datos",
        "forgot_password": "He olvidado mi contraseña",
        "enter": "Entrar",
        "dont_have_account": "¿No tienes cuenta?",
        "create_one": "Crear una",
        "alert_client_error": "Se ha producido un error en la interacción con su navegador.",
        "alert_minimal_support": "Usted está usando Internet Explorer 10+ u otro navegador antiguo, anticuado y con problemas de seguridad. Para tener una experiencia de uso completo y seguro utilice  Google Chrome, Mozilla Firefox o Safari.",
        "alert_not_supported": "Usted está utilizando Internet Explorer 9 o inferior, un navegador antiguo, anticuado y con problemas de seguridad. Para tener una experiencia de uso completo y seguro utilice Google Chrome, Mozilla Firefox o Safari.",
        "alert_not_found": "Ops ... no entra ningún registro aquí.",
        "alert_offline": "Offline,",
        "loading": "Cargando",
        "see_all": "Ver todas",
        "mark_as_read": "Marcar como leídas",
        "new_notification": "Nueva notificación",
        "notification_received": "Recibido",
        "notification_sent": "Expedido",
        "notification_empty": "Por el momento, ninguna notificación para usted.",
        "preferences": "Preferencias",
        "repos": "Bibliotecas",
        "advanced": "Avanzado",
        "terms": "Terminos de uso",
        "terms_privacy": "Privacidad y seguridad",
        "terms_technical": "Nivel de servicio",
        "api": "Documentación API",
        "changelog": "Qué hay de nuevo",
        "ead": "Universidad",
        "content_library": "Biblioteca educativa",
        "zapier": "Integración vía Zapier",
        "tour": "Tour interactivo",
        "select": "Seleccione",
        "completed": "Completado",
        "no_completed": "No Completado",
        "aprroved": "Aprobado",
        "rejected": "Rechazado",
        "selectdate":"Seleccionar fecha",
        "date_picker_locale" :"es",
        "date_picker_format" :"d/m/Y",
        "nothing_likely" :"1 - Nada probable",
        "extremely_likely" :"10 - Mucho probable",
        "see_more":"Vea mas...",
        "no_item_found":"Ningún record fue encontrado",
        "privacy_message": "Utilizamos cookies y tecnologías similares para mejorar su experiencia en nuestros servicios. Si continúa navegando, acepta estas condiciones.",
        "privacy_button": "Continuar",
        "system_notfound_1": "Parece que la dirección que ingresaste es incorrecta.",
        "system_notfound_2": "No pudimos encontrar su sistema en esta dirección.",
        "system_notfound_3": "Verifique que la dirección que ingresó sea correcta.",
        "system_notfound_4": "Si continúa teniendo problemas, comuníquese con el administrador de la plataforma de su empresa.",
        "constructor_workflow": "Flujo de trabajo", 
        "constructor_form": "Formar",    
        "constructor_fiels_activity": "Campos x Actividad",  
        "constructor_salve": "Ahorrar",  
        "constructor_able": "Poder", 
        "constructor_visible": "Visible",    
        "constructor_manipulable": "Manipulable",     
        "constructor_not_manipulable": "No manipulable",    
        "constructor_create_form_message": "Este proceso no tiene forma asociada. Quieres crear uno?", 
        "constructor_create_new_form": "Crear nuevo", 
        "constructor_existing_form": "Formulário existente", 
        "constructor_add_fields": "Agregar campos",    
        "constructor_field_text": "Texto",  
        "constructor_field_text_area": "Área de texto",    
        "constructor_field_date": "Fecha",
        "constructor_field_email": "Correo electrónico",
        "constructor_field_multiple_selection": "Selección múltiple",
        "constructor_field_hour": "Hora",
        "constructor_field_numbers": "Números",
        "constructor_field_telephone": "Teléfono",
        "constructor_field_archive": "Archivo",
        "constructor_field_zip_code": "Código postal",
        "constructor_field_currency": "Moneda",
        "constructor_field_nps": "NPS",
        "constructor_field_multiple_options": "Múltiples opciones",
        "constructor_field_multiple_search": "Buscar",
        "constructor_field_board": "Tablero",
        "constructor_field_cell_phone": "Teléfono móvil",
        "constructor_field_cpf": "CPF",
        "constructor_field_password": "Contraseña",
        "constructor_field_cnpj": "CNPJ",
        "constructor_field_rg": "RG",
        "constructor_field_hidden": "Oculto",
        "constructor_field_suggestion": "Sugerencia",
        "constructor_new_section": "Nueva sección +",
        "constructor_field_ownership": "Propiedad del campo",
        "constructor_advanced_options": "Opciones avanzadas",
        "select_an_option": "Seleccione una opción",
               
    }
};


tsml.htmlTagLang = function() {
    return (document.querySelector("html").getAttribute("lang") || "pt").replace("-", "_").toLowerCase()
};

tsml.getTranslation = function(locale_key) {

    var html_tag_lang = tsml.htmlTagLang();
    locale_key = tsml.adjustForbiddenKeys(locale_key);
    var resource_value = eval("resource." + html_tag_lang + "." + locale_key);
    if (resource_value) {
        return resource_value;
    }

}

tsml.translate = function(container) {

    var elements = container.querySelectorAll("[data-locale-title], [data-locale-html], [data-locale-placeholder]");
    h.forEach(elements, function(item, el) {

        var html_tag_lang = tsml.htmlTagLang();

        var locale_title = el.getAttribute("data-locale-title");
        var locale_html = el.getAttribute("data-locale-html");
        var locale_placeholder = el.getAttribute("data-locale-placeholder");

        if (locale_title != null && locale_title != "") {
            locale_title = tsml.adjustForbiddenKeys(locale_title);
            var resource_title = eval("resource." + html_tag_lang + "." + locale_title);
            if (resource_title) {
                el.setAttribute("title", resource_title);
            }
        }

        if (locale_html != null && locale_html != "") {
            locale_html = tsml.adjustForbiddenKeys(locale_html);
            var resource_html = eval("resource." + html_tag_lang + "." + locale_html);
            if (resource_html) {
                el.innerHTML = resource_html;
            }
        }

        if (locale_placeholder != null && locale_placeholder != "") {
            locale_placeholder = tsml.adjustForbiddenKeys(locale_placeholder);
            var resource_placeholder = eval("resource." + html_tag_lang + "." + locale_placeholder);
            if (resource_placeholder) {
                el.setAttribute("placeholder", resource_placeholder);
            }
        }

    });
};

tsml.adjustForbiddenKeys = function(key) {
    if ("new".indexOf(key) >= 0) {
        return "_" + key;
    } else {
        return key;
    }
}